import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AuthService } from "src/app/shared/services/auth.service";
import { DrawerService } from "src/app/shared/services/drawer.service";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
  ],
  templateUrl: "./site-header.component.html",
  styleUrls: ["./site-header.component.scss"],
})
export class SiteHeaderComponent implements OnInit {
  status: any;

  constructor(
    public drawerService: DrawerService, // public UserDataService: UserDataService, // public userService: UserService,
    public authService: AuthService,
  ) {}

  ngOnInit() {}
}
