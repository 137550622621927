<mat-nav-list>
  <mat-accordion>
    <ng-container *ngFor="let panel of links">
      <mat-list-item
        *ngIf="!panel.levelTwo.length"
        routerLinkActive="active"
        routerLink="{{ panel.url }}"
      >
        <div class="nav-item">
          <mat-icon mat-list-icon>{{ panel.icon }}</mat-icon>
          <span>{{ panel.title }}</span>
        </div>
      </mat-list-item>

      <mat-expansion-panel
        *ngIf="panel.levelTwo.length"
        [expanded]="tab === panel.url"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="nav-item">
              <mat-icon mat-list-icon>{{ panel.icon }}</mat-icon>
              <span>{{ panel.title }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list-item
          *ngFor="let item of panel.levelTwo"
          routerLinkActive="active"
          routerLink="{{ item.url }}"
          appAppDrawer
        >
          <div class="nav-item">
            <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
            <span>{{ item.title }}</span>
          </div>
        </mat-list-item>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</mat-nav-list>
