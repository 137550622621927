import { Injectable } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";

@Injectable({
  providedIn: "root",
})
export class DrawerService {
  private _drawer: MatDrawer;

  public get drawer(): MatDrawer {
    return this._drawer;
  }
  public set drawer(value: MatDrawer) {
    this._drawer = value;
  }

  public toggle() {
    this.drawer.toggle();
  }
}
