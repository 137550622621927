import { INavItem } from "../interfaces/common.interface";

export const NAV_MENU_LIST: INavItem[] = [
  {
    levelOneId: "",
    url: "claims",
    title: "Claims",
    icon: "receipt",
    levelTwo: [],
  },
  {
    levelOneId: "",
    url: "policies",
    title: "Policies",
    icon: "verified",
    levelTwo: [],
  },
];
