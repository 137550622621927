import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { AuthService } from "../../services/auth.service";
import { INavItem } from "../../interfaces/common.interface";
import { NAV_MENU_LIST } from "../../constants/navigation.global";

@Component({
  selector: "app-site-nav",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
  ],
  templateUrl: "./site-nav.component.html",
  styleUrls: ["./site-nav.component.scss"],
})
export class SiteNavComponent {
  tab: string;
  links: INavItem[] = NAV_MENU_LIST;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.tab = this.router.url.split("/")[1];
  }
}
