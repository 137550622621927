export const api = {
  /*LOGIN */
  LOGIN: "/api/User/Login",
  GET_PROFILE: "/api/User/GetProfile",

  /* CLAIM */
  CLAIM_P_GET_LIST: "/api/Claim/GetListing",
  CLAIM_G_GET_BY_ID: "/api/Claim/GetById",
  CLAIM_P_DOWNLOAD_CLAIM_DOCS: "/api/Claim/DownloadClaimDocuments",
  CLAIM_G_GET_REPORT_DETAIL_BY_ID: "/api/Claim/GetInspectionReportDetailById",
  CLAIM_P_GET_LISTING_EXCEL: "/api/Claim/GetListingExcel",
  CLAIM_P_UPDATE_STATUS: "/api/Claim/UpdateStatus",

  /* POLICY */
  POLICY_GET_LISTING: "/api/Policy/GetListing",
  POLICY_GET_BY_ID: "/api/Policy/GetById",
  POLICY_GET_DETAIL_CLAIM_LISTING: "/api/Policy/GetDetailClaimListing",
  POLICY_GET_LISTING_EXCEL: "/api/Policy/GetListingExcel",

  /* GENERAL */
  DOWNLOAD_BY_ID: "/api/Document/DownloadById",
};
