import { Component, HostListener, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { SiteHeaderComponent } from "src/app/shared/components/site-header/site-header.component";
import { SiteNavComponent } from "../../components/site-nav/site-nav.component";
import { ALL_SCREEN_SIZES } from "../../constants/constants.global";
import { IScreenSize } from "../../interfaces/common.interface";
import { DrawerService } from "../../services/drawer.service";

@Component({
  selector: "app-dashboard",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    SiteHeaderComponent,
    SiteNavComponent,
  ],
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  @ViewChild("drawer") public drawer: MatDrawer;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }

  screenWidth: number;
  screenSizes: IScreenSize = ALL_SCREEN_SIZES;

  constructor(private drawerService: DrawerService) {
    this.screenWidth = window.innerWidth;
  }

  ngAfterContentChecked(): void {
    this.drawerService.drawer = this.drawer;
  }

  ngOnInit() {}
}
