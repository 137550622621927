import { Routes } from "@angular/router";
import { DashboardComponent } from "./shared/layouts/dashboard/dashboard.component";
import { AuthGuard } from "./shared/services/guard.service";

export const APP_ROUTES: Routes = [
  {
    path: "authentication",
    loadChildren: () =>
      import("./modules/authentication/auth.routes").then((m) => m.AUTH_ROUTES),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      { path: "", pathMatch: "full", redirectTo: "claims" },
      {
        path: "policies",
        loadChildren: () =>
          import("./modules/policies/policies.routes").then(
            (r) => r.POLICIES_ROUTES,
          ),
      },
      {
        path: "claims",
        loadChildren: () =>
          import("./modules/claims/claims.routes").then((r) => r.CLAIMS_ROUTES),
      },
    ],
  },
];
